import { createSlice } from "@reduxjs/toolkit";
import { fetchPostToken, fetchPostTokenCV, fetchToken } from "../../customHooks/hooks";

// {
//     "$id": String,
//     "id": Number,
//     "cargo": String,
//     "gerencia": String,
//     "estamento": String,
//     "ubicacion": String,
//     "jornada": String,
//     "mision": String,
//     "funciones": "String",
//     "descripcion": "String",
//     "archivo": "String",
//     "fecha_vencimiento": "2022-11-30T00:00:00"
//   },

export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    totalPosts: [
      {
        data: {
          id: 0,
          cargo: "",
          gerencia: "",
          estamento: "",
          ubicacion: "",
          jornada: "",
          mision: "",
          funciones: "",
          descripcion: "",
          archivo: "",
          fecha_vencimiento: "",
          finalizado : "",
          postulantes: 12
        },
        listadoHabilidades: [],
        listadoIntereses: [],
        listadoLenguajes: []
      }
    ],
    selectors: {
      gerencias: [],
      ubicaciones: [],
      estamentos: [],
      jornadas: [],
      lideres: [],
      perfilesLider: []
    }
  },
  reducers: {
    setTotalPosts: (state, action) => {
      state.totalPosts = action.payload.post.filter(objeto => objeto.data.Pais == action.payload.pais && objeto.data.finalizado == 0);
      console.log(state.totalPosts.length)

    },
    setSelectors: (state, action) => {
      state.selectors.gerencias = action.payload.gerencias;
      state.selectors.ubicaciones = action.payload.ubicaciones;
      state.selectors.estamentos = action.payload.estamentos;
      state.selectors.jornadas = action.payload.jornadas;
      state.selectors.lideres = action.payload.lideres;
      state.selectors.perfilesLider = action.payload.perfilesLider;

    }
  }
});

const { setTotalPosts, setSelectors } = postsSlice.actions;

export const fetchGetPosts = (pais) => async dispatch => {
  const posts = await fetchToken({
    endpoint: "Publicacion/GetAllPublicacion",
    alt: [{ error: true }]
  });

  dispatch(setTotalPosts({post:posts,pais:pais}));
};

export const addPost = (post, callback,token,archivos) => async () => {
  await fetchPostToken({
    endpoint: "Publicacion/Add",
    method: "POST",
    body: post,
    alt: { error: true },
    token:token,
    archivos:archivos
  });
  // callback();
};
export const addCV = (post,archivos) => async () => {
  await fetchPostTokenCV({
    endpoint: "Persona/AddCV",
    method: "POST",
    body: post,
    alt: { error: true },
    archivos:archivos
  });
  // callback();
};
export const addSugerida = (post, callback) => async () => {
  await fetchToken({
    endpoint: "Habilidad/AddSugerida",
    method: "POST",
    body: post,
    alt: { error: true }
  });
  callback();
};
export const updPost = (post, callback, token,archivos) => async () => {
  await fetchPostToken({
    endpoint: "Publicacion/Upd",
    method: "POST",
    body: post,
    alt: { error: true },
    token:token,
    archivos:archivos
  });
  // callback();
};
export const addPostDestacada = (post, callback, token,archivos) => async () => {
  await fetchPostToken({
    endpoint: "Publicacion/AddDestacada",
    method: "POST",
    body: post,
    alt: { error: true },
    token:token,
    archivos:archivos
  });

  //  callback();
};
export const fetchGetSelectors = (pais,token) => async dispatch => {
  const selectors = await fetchToken({
    endpoint: "Selectores/GetAll",
    alt: { error: true },
    token:token
  });

  if (!selectors.error) {
    dispatch(
      setSelectors({
        gerencias: selectors.listadoGerencias,
        ubicaciones: selectors.listadoUbicaciones.filter(objeto => objeto.pais === pais),
        estamentos: selectors.listadoEstamentos,
        jornadas: selectors.listadoJornadas,
        lideres: selectors.listadoLideres.filter(objeto => objeto.Pais === pais),
        perfilesLider: selectors.listadoPerfilesLideres.filter(objeto => objeto.Pais === pais)
      })
    );
  }
};
export default postsSlice.reducer;
