import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Hr, Title } from "../../../Styled";
import Post from "./Post/Post";
import { PostEmptyContainer, WrapperPosts } from "./Styled";
import { fetchGetPosts } from "../../../store/slices/posts";
import { ImgEmpty } from "../Home/Feed/Caps/Styled";
import { PATH } from "../../../assets/variables";
import { fetchToken } from "../../customHooks/hooks";
import { setUserAllData } from "../../../store/slices/userAll";

const MyPosts = () => {
  const posts = useSelector(store => store.posts.totalPosts);
  const user = useSelector(store => store.user.data)
    const token = useSelector(store => store.usuario.token);
  
  const dispatch = useDispatch();
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: "Persona/GetAll", token:token });
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetAllTags" });
    const dataInteres = await fetchToken({ endpoint: "Interes/GetAllTags" });
    const dataLenguaje = await fetchToken({ endpoint: "Lenguaje/GetAllTags" });
    const skills = dataSkills;
    const interes = dataInteres;
    const lenguaje = dataLenguaje;


    let newData = dataUsers.map(item =>
      Object.assign({}, item, { habilidad: [], intereses: [], lenguajes: [] })
    );

    skills.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.habilidad.push(obj.nombre);
        }
      });
    });

    interes.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.intereses.push(obj.nombre);
        }
      });
    });
    lenguaje.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.lenguajes.push(obj.nombre);
        }
      });
    });
    dispatch(setUserAllData({post:newData,pais:'Peru'}));
  };

  useEffect(() => {
    dispatch(fetchGetPosts('Peru'));
    FetchData()
  }, []);

  return (
    <WrapperPosts>
      <Title>Mis Publicaciones</Title>
      <Hr />
      {posts.length > 0 ? ( 
        posts.filter(fil => fil.data.fk_persona === user.fk_colaborador).map(post => {
          if(post.data.fk_persona === user.fk_colaborador)
          {
            return (
              <Post key={post.data.id} post={post} />
            )
          }

          else{
            return "No"
          }
        })
      ) : (
        <>
          {/* {[1, 2].map(x => (
            <PostEmptyContainer key={x}>
              <div>
                <SkeletonLoader />
              </div>
              <section>
                <TextSkeleton width="100%" />
                <div>
                  {[1, 2, 3, 4, 5, 6].map(i => (
                    <div key={i}>
                      <SkeletonLoader />
                    </div>
                  ))}
                </div>
                <div></div>
              </section>
            </PostEmptyContainer>
          ))} */}
      <ImgEmpty>
        <img src={PATH + "img/sin_posts.svg"} />
      </ImgEmpty>
        </>
      )}

    </WrapperPosts>
  );
};

export default MyPosts;
{/* <Post key={post.data.id} post={post} /> */}