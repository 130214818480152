import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchGetSelectors } from "../../store/slices/posts";
import DataTypeMaintainers from "./DataTypeMaintainers/DataTypeMaintainers";
import { ContainerMaintainers } from "./Styled";
import TypesMaintainers from "./TypesMaintainers/TypesMaintainers";
import { setUserAllData } from "../../store/slices/userAll";
import { fetchToken } from "../../customHooks/hooks";

const Maintainers = () => {
  const dispatch = useDispatch();
  const dataUser = useSelector(store => store.user.data)
  const {data,token} = useSelector(store => store.usuario);
  const navigate = useNavigate()
  if(dataUser.administrador == false){
    navigate('/inicio')
  }


  useEffect(() => {

    dispatch(fetchGetSelectors(dataUser.Pais,token));
  }, [dispatch]);

  return (
    <ContainerMaintainers>
      <TypesMaintainers />
      <DataTypeMaintainers />
    </ContainerMaintainers>
  );
};

export default Maintainers;
