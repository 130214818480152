import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH, colors } from "../../../assets/variables";
import { fetchToken } from "../../../customHooks/hooks";
import { ContainerModal, Hr, SubTitle, Title } from "../../../Styled";
import { calculator } from "../../../utils/calculator";
import Alert from "../../Common/Alert/Alert";
import { ContainerButton, InputStyled } from "../../Common/Alert/Styled";
import { Icon } from "../../Common/IconProfile/Styled";
import { SkeletonLoader } from "../../Common/Skeleton/Styled";
import { SkeletonContainer } from "../../PostDetails/PostItems/Styled";
import { BtnClose, ModalWrapper, Section } from "../../PostDetails/PostModal/Styled";
import { WhiteButton } from "../../Search/Results/Styled";
import { BtnBlue, BtnCancelar } from "../../VacanciesDetails/MainVacancy/styled";
import { OffSet } from "../../VacanciesDetails/Style";
import { ContainerHalfCustom } from "../Styled";
import {v4} from "uuid"

import {
  ItemBox,
  HeaderSection,
  RowWrapper,
  Score,
  Img,
  InputSearchFilter,
  SearchContainer,
  ContainerPostulante,
  SectionAddPostulante,
  ContainerFinalizar
} from "./Styled";
import CapSkills from "../../Home/Feed/Caps/Cap/CapSkills/CapSkills";
import { añosCargo, capitalizeWords } from "../../../utils/utilidades";
import { setUserAllData } from "../../../store/slices/userAll";
import { fetchGetPosts } from "../../../store/slices/posts";

const Applicant = ({ setShowModal, m, postulante, func }) => {
  const [showDetailsUser, setshowDetailsUser] = useState([]);
  const [showDetailsUserTags, setshowDetailsUserTags] = useState([]);
  const [showModal, setshowModal] = useState(false);

  const userData = useSelector(store => store.userAll);
  function showDetailsProfile(id){
    setshowModal(true)
    const searchObject= userData.datos.find((user) => user.id==id);
    const skillsMap = searchObject.habilidad.map(tag => ({ id: v4(), title: tag}));
    const interesesMap = searchObject.intereses.map(tag => ({ id: v4(), title: tag}));
    const lenguajesMap = searchObject.lenguajes.map(tag => ({ id: v4(), title: tag}));
    const tagsObject = {
      skills: skillsMap,
      interests:interesesMap,
      languages:lenguajesMap
    }
   
    
    setshowDetailsUser(searchObject)
    setshowDetailsUserTags(tagsObject)
  }
  const [modalCalendario, setmodalCalendario] = useState(false)
  const [modalAira, setmodalAira] = useState(false)
  const [modalEliminar, setmodalEliminar] = useState(false)
  const [modalSeleccionado, setmodalSeleccionado] = useState(false)

  
  const [idPost, setIdPost] = useState("");
  const [campo, setCampo] = useState("");
  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const [evento, setEvento] = useState("");
  const [fkPersona, setfkPersona] = useState("");

  const showAgendar = e => {

    setIdPost(e.target.dataset.id)
    setCorreo(e.target.dataset.correo)
    setNombre(e.target.dataset.nombre)
    setfkPersona(e.target.dataset.fkpersona)
    setmodalCalendario(true)
  }
  const showAira = e => {

    setIdPost(e.target.dataset.id)
    setCorreo(e.target.dataset.correo)
    setNombre(e.target.dataset.nombre)
    setfkPersona(e.target.dataset.fkpersona)
    setmodalAira(true)
  }
  const showEliminar= e => {

    setIdPost(e.target.dataset.id)
    setCorreo(e.target.dataset.correo)
    setNombre(e.target.dataset.nombre)
    setfkPersona(e.target.dataset.fkpersona)
    setmodalEliminar(true)
  }
  const showAceptado = e =>{
    setIdPost(e.target.dataset.id)
    setCorreo(e.target.dataset.correo)
    setNombre(e.target.dataset.nombre)
    setfkPersona(e.target.dataset.fkpersona)
    setmodalSeleccionado(true)
  }
  const onChangeCampo = event => {
    setCampo(event.target.value);
 };
  const agendarPost = async () =>{
    const dataUser = {
      id: idPost,
      correo : correo,
      idPersona :fkPersona,
      fecha : campo
    };
   const userSelected = await fetchToken({
      endpoint: "Publicacion/AddAgendar",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setmodalCalendario(false)
      func();
    }
  }
  const sendAira = async () =>{
    const dataUser = {
      id: idPost,
      correo : correo,
      idPersona :fkPersona,
      campo : campo
    };
   const userSelected = await fetchToken({
      endpoint: "Persona/AddAira",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setmodalAira(false)
      func();
    }
  }
  const sendEliminar = async () =>{
    const dataUser = {
      id: idPost,
      correo : correo,
      idPersona :fkPersona,
      campo : campo
    };
   const userSelected = await fetchToken({
      endpoint: "Persona/DeletePostulante",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setmodalEliminar(false)
      func();
    }
  }
  const sendAceptar = async () =>{
    const dataUser = {
      id: idPost,
      correo : correo,
      idPersona :fkPersona,
      campo : campo
    };
   const userSelected = await fetchToken({
      endpoint: "Persona/PostulanteAceptado",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setmodalSeleccionado(false)
      func();
    }
  }

  return (
    <>
    { postulante === "postulo" ? ( (m.postulo === 1 && m.seleccionado != 1) &&
          <RowWrapper $padding=" 11px 0">
            <ItemBox $width=" 8% ">
              <Icon
                back={m.background}
                size="32px"
                img={`${PATH}perfil/profile${m.img}.svg`}
              />
            </ItemBox>
            <ItemBox $width="243px">
              <Title size="14px">{capitalizeWords(m.nombreCompleto)}</Title>
            </ItemBox>
            <ItemBox $width="58px">
              <Score back={m.average}>
                <p>{m.average >= 100 ? 100 : m.average }%</p>
              </Score>
            </ItemBox>
            <ItemBox $width="70.5px">
              <img src={PATH + "img/Info.svg"} alt="info" onClick={()=> showDetailsProfile(m.fk_persona)}/>
            </ItemBox>
            <ItemBox grow="1" onClick={()=> setmodalEliminar(!m.eliminado)}>
            {!m.eliminado ? (
              <button onClick={showEliminar}>
                <img data-nombre={capitalizeWords(m.nombreCompleto)} data-correo={m.email} data-id={m.idpost} data-fkpersona={m.fk_persona} src={PATH + "img/less.svg"} alt="less" title="Eliminar postulante" />
              </button>
            ) : (
              <button>
              <img src={PATH + "img/eliminarok.svg"} alt="less" title="Postulante eliminado" />

              </button>
            ) 
            }
            </ItemBox>
          </RowWrapper>
    )
    : (
         m.seleccionado != undefined &&
          <RowWrapper $padding=" 11px 0">
            <ItemBox $width=" 8% ">
              <Icon
                back={m.background}
                size="32px"
                img={`${PATH}perfil/profile${m.img}.svg`}
              />
            </ItemBox>
            <ItemBox $width="213px">
            {!m.eliminado ? <Title size="14px">{capitalizeWords(m.nombreCompleto)}</Title> : <h6>{capitalizeWords(m.nombreCompleto)}</h6>}
              
            </ItemBox>
            <ItemBox $width="58px">
              <Score back={m.average}>
                <p>{m.average >= 100 ? 100 : m.average }%</p>
              </Score>
            </ItemBox>
            <ItemBox $width="70.5px">
              <img src={PATH + "img/Info.svg"} alt="info" onClick={()=> showDetailsProfile(m.fk_persona)}/>
            </ItemBox>
            <ItemBox $width="32px">
            {!m.eliminado ? (
              !m.agendado ? (
              <button  onClick={showAgendar}>
              <Img data-nombre={capitalizeWords(m.nombreCompleto)} data-correo={m.email} data-id={m.idpost} data-fkpersona={m.fk_persona} src={PATH + "img/Calendar.svg"} alt="calendar" title="Agendar entrevista" />
              </button>
                            ) : <Img src={PATH + "img/Calendarok.svg"} alt="calendar" title="Postulante agendado" />
            ) : <Img src={PATH + "img/CalendarDisable.svg"} alt="calendar" />
            }
            </ItemBox>
            <ItemBox $width="32px">
            {!m.eliminado ? (
              !m.aira ? (
              <button onClick={showAira}>
                <img data-nombre={capitalizeWords(m.nombreCompleto)} data-correo={m.email} data-id={m.idpost} data-fkpersona={m.fk_persona} src={PATH + "img/aira.svg"} alt="aira" title="Compartir link de Aira" />
              </button>
            ) : <Img src={PATH + "img/airaok.svg"} alt="aira" title="Link de aira enviado " />
            )  : <Img src={PATH + "img/airaDisable.svg"} alt="aira" />
            }


            </ItemBox>
            <ItemBox grow="1">
            {!m.eliminado ? (
              <button onClick={showEliminar}>
                <img data-nombre={capitalizeWords(m.nombreCompleto)} data-correo={m.email} data-id={m.idpost} data-fkpersona={m.fk_persona} src={PATH + "img/less.svg"} alt="less" title="Eliminar postulante" />
              </button>
            ) : <img src={PATH + "img/eliminarok.svg"} alt="less" title="Postulante eliminado" />
            }
            </ItemBox>
            <ItemBox grow="1" >
            {
              !m.eliminado ? (
                !m.aceptado ? (
                <button onClick={showAceptado}>
                  <img data-nombre={capitalizeWords(m.nombreCompleto)} data-correo={m.email} data-id={m.idpost} data-fkpersona={m.fk_persona} src={PATH + "img/icono-seleccionado.svg"} alt="less" title="Seleccionar postulante" />
                </button>
              ) : <img src={PATH + "img/selecionadoOk.svg"} alt="less" title="Postulante seleccionado" />
              ) : <img src={PATH + "img/seleccionadoDisable.svg"} alt="less" />
            }


            </ItemBox>
          </RowWrapper>
           )

    }


    <Alert
      estado={modalCalendario}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>Selecciona el día que deseas agendar la entrevista
         </p>
        <InputStyled>
        <input type="date" value={campo} onChange={onChangeCampo}/>
        </InputStyled>
        <ContainerButton>
          <BtnCancelar width={"150px"} onClick={()=> setmodalCalendario(false)}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={agendarPost}>Agendar</BtnBlue>
        </ContainerButton>

      </Alert>
      <Alert
      estado={modalAira}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>Agrega el link de Aira que deseas compartir
         </p>
        <InputStyled>
        <input type="text" value={campo} onChange={onChangeCampo} placeholder=" "/>
        <label htmlFor="">Link enlace:</label>
        </InputStyled>
        <ContainerButton>
          <BtnCancelar width={"150px"} onClick={()=> setmodalAira(false)}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={sendAira}>Agregar</BtnBlue>
        </ContainerButton>

      </Alert>
      <Alert
      estado={modalEliminar}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>
        ¿Estás seguro de que deseas Eliminar?
         </p>
         <h6>Motivo (maximo 500 caracteres):</h6>
         <textarea name="" id="" cols="40" rows="15" value={campo} onChange={onChangeCampo}/>
        <ContainerButton>
          <BtnCancelar width={"150px"} onClick={()=> setmodalEliminar(false)}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={sendEliminar}>Eliminar</BtnBlue>
        </ContainerButton>

      </Alert>
      <Alert
      estado={modalSeleccionado}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>
        ¿Estás seguro de que deseas Enviar la notificación de que ha sido seleccionado a <strong>{nombre}</strong>?
         </p>
         <h6>Motivo (maximo 500 caracteres):</h6>
         <textarea name="" id="" cols="40" rows="15" value={campo} onChange={onChangeCampo}/>
        <ContainerButton>
          <BtnCancelar width={"150px"} onClick={()=> setmodalSeleccionado(false)}>Cancelar</BtnCancelar>
          <BtnBlue width={"150px"} onClick={sendAceptar}>Notificar</BtnBlue>
        </ContainerButton>

      </Alert>
      {showModal && (
        <ContainerModal>
          <ModalWrapper>
            <BtnClose onClick={() => setshowModal(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
            <Section>
              <div>
                <Icon img={PATH + `perfil/profile${showDetailsUser.img}.svg`} size="80px"
                back={
                  showDetailsUser?.background ? showDetailsUser.background : colors.graySkeleton
                }
                 />
              </div>
              <div>
                <Title mb="0" size="17px">
                   {capitalizeWords(showDetailsUser.nombreCargo)}
                </Title>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Gerencia actual: <span>{capitalizeWords(showDetailsUser.nombreVpGerencia)}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Estamento: <span>{showDetailsUser.estamento}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Nivel de cargo: <span>{showDetailsUser.nivelCargo}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                  Tiempo en el cargo: <span>{ añosCargo(showDetailsUser.fechaInicioCargo)} {añosCargo(showDetailsUser.fechaInicioCargo) <= 1 ? 'año' : 'años'}</span>
                </SubTitle>
                <SubTitle lh="20px" $margin="0" size="14px">
                Evaluación desempeño: <span>{showDetailsUser.desempCriterio != null ? showDetailsUser.desempCriterio : 'Sin evaluación'}</span>
                </SubTitle>
              </div>
            </Section>
            <Hr $margin="12px" />
            {
              showDetailsUser.nombreUrl != null && (
                <>
                <div style={{display:'flex',alignItems:'center',gap:9}}>
                  <img style={{width:28,height:28}} src="https://colaboradordigital.blob.core.windows.net/ematch/img/icono-adobe.png" alt="adobe" />
                  <a style={{color:'#0C0C0C', fontSize:14}} href={`https://colaboradordigital.blob.core.windows.net/ematch/cv/${showDetailsUser.nombreUrl}`} target="_blank" rel="noopener noreferrer">{showDetailsUser.nombreArchivo}</a>
                </div>
                <Hr $margin="12px" />
                </>
              )
            }
            <CapSkills itemsData={showDetailsUserTags} />
            <Hr $margin="24px" />
            <div>
              <Title mb="0" size="16px">
                Descripcion personal:
              </Title>
              <SubTitle lh="24px" $margin="0" size="16px">
                {showDetailsUser.descripcion}
              </SubTitle>
            </div>
            <Hr $margin="24px" />
          </ModalWrapper>
        </ContainerModal>
      )}
    </>
  );
};

const MatchesItems = ({ setShowModal, idPost, postulante }) => {
  const [modalNoCompartir, setModalNoCompartir] = useState(false)
  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalCompartir, setModalCompartir] = useState(false);
  const [idPostCompartir, setIdPost] = useState("");
  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const {data,token} = useSelector(store => store.usuario);


  const showCompartir = e =>{
    setIdPost(e.target.dataset.id)
    setCorreo(e.target.dataset.correo)
    setNombre(e.target.dataset.nombre)

    setModalAdd(false)
    setModalCompartir(true)
  }
  const [showDetailsUserTags, setshowDetailsUserTags] = useState([]);

  const userData = useSelector(store => store.userAll);
  const showNoCompartir = e =>{
    const searchObject= userData.datos.find((user) => user.id==e.target.dataset.id);
    const skillsMap = searchObject.habilidad.map(tag => ({ id: v4(), title: tag}));
    const interesesMap = searchObject.intereses.map(tag => ({ id: v4(), title: tag}));
    const lenguajesMap = searchObject.lenguajes.map(tag => ({ id: v4(), title: tag}));
    const tagsObject = {
      skills: skillsMap,
      interests:interesesMap,
      languages:lenguajesMap
    }
    setshowDetailsUserTags(tagsObject)
    setNombre(e.target.dataset.nombre)
    setModalAdd(false)
    setModalNoCompartir(true)
  }
  const compartirPost = async () =>{
    const dataUser = {
      id: idPostCompartir,
      correo : correo
    };
   const userSelected = await fetchToken({
      endpoint: "Publicacion/AddCompartir",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setModalCompartir(false)
      setSearchTerms([])
    }
  }
  const [showBtnFinalizar, setShowBtnFinalizar] = useState(false);
   const showAlertFinalizar = async e =>{
    const dataUser = {
      id: e.target.dataset.id
    };
   const userSelected = await fetchToken({
      endpoint: "Publicacion/AddFinalizar",
      method: "POST",
      body: dataUser,
      alt: { error: true }
    });
    if(userSelected.FlgOk === 1){
      setModal(false)
      setShowBtnFinalizar(true)
      dispatch(fetchGetPosts());
    }
   } 
  const finalizarPost = e => {
  };
  const [searchTerm, setSearchTerms] = useState([]);
  const handleChange = event => {
    setSearchTerms(event.target.value);
  };
  const userPerfiles = useSelector(store => store.userAll.datos);
  const userMatches = useSelector(store => store.userAll.match);
  const tokenUser = useSelector(store => store.usuario.token);

  const dispatch = useDispatch();
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: "Persona/GetAll",token:token });
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetAllTags" });
    const dataInteres = await fetchToken({ endpoint: "Interes/GetAllTags" });
    const dataLenguaje = await fetchToken({ endpoint: "Lenguaje/GetAllTags" });
    const skills = dataSkills;
    const interes = dataInteres;
    const lenguaje = dataLenguaje;


    let newData = dataUsers.map(item =>
      Object.assign({}, item, { habilidad: [], intereses: [], lenguajes: [] })
    );

    skills.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.habilidad.push(obj.nombre);
        }
      });
    });

    interes.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.intereses.push(obj.nombre);
        }
      });
    });
    lenguaje.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.lenguajes.push(obj.nombre);
        }
      });
    });
    dispatch(setUserAllData(newData));
  };
  const { listadoHabilidades, listadoLenguajes, listadoIntereses } =
    useSelector(
      store =>
        store.posts.totalPosts.filter(
          post => post.data.id === Number(idPost)
        )[0]
    );
  const [loading, setLoading] = useState(false);

  const [matches, setMatches] = useState([]);
  const [escuchaEvento, setEscuchaEvento] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await fetchToken({
        endpoint: `Publicacion/GetTokenMatchTagsAdmin?id=${idPost}`,
        alt: { dataSkills: [], dataLenguajes: [] }, token:token
      });

      const calc = calculator({
        listadoLenguajes,
        listadoHabilidades,
        listadoIntereses,
        results
      });

      setMatches(calc);
      setLoading(false);
    })();
  }, [escuchaEvento]);
  useEffect(() => {
    FetchData();
  }, []);
  return (
    <div>
      {postulante === "postulo" ? "" : <OffSet />}
      <ContainerHalfCustom
        $max={matches.length === 0}
        section_scroll
        header_height="78"
        postulante={postulante}
      >
        <HeaderSection>
          <SectionAddPostulante>
          <Title mb="0" size="16px">{postulante === "postulo" ? "Postulantes" : "Coicidencias:"}</Title>
          {postulante === "postulo" ? ":" : (<button onClick={()=>setModalAdd(true)}>Agregar postulante</button>)}
          </SectionAddPostulante>

          <RowWrapper>
            <ItemBox $width="302px" p="0 0 0 40px">
              <Title size="14px">Nombre</Title>
            </ItemBox>
            <ItemBox $width="127px">
              <Title size="14px">%</Title>
            </ItemBox>
            <ItemBox $width="72px">
              <Title size="14px">Acciones</Title>
            </ItemBox>
          </RowWrapper>
        </HeaderSection>

        <section>
          {loading ? (
            <>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
              <SkeletonContainer>
                <SkeletonLoader />
              </SkeletonContainer>
            </>
          ) : matches.length > 0 ? (
            matches.map(m => (
              <Applicant key={m.fk_persona} m={m} setShowModal={setShowModal} postulante={postulante} func={e => {setEscuchaEvento(true)}} />
            ))
          ) : (
            <Title align="center" size="24px" bold="400">
              No hay resultados
            </Title>
          )}
        </section>
        {showBtnFinalizar === false ? (<ContainerFinalizar><BtnBlue onClick={()=> setModal(true)}>
          Finalizar publicación
          </BtnBlue></ContainerFinalizar>) : (
            <ContainerFinalizar>
            <BtnBlue disabled>
            <p>Publicación Finalizada</p>
            </BtnBlue>
            </ContainerFinalizar>
          ) }


      </ContainerHalfCustom>
      <Alert
      estado={modal}
      shoModal={setModal}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas finalizar la publicación?
        </p>
        <div>
        <ContainerButton>
        <BtnCancelar onClick={()=> {setModal(false);setSearchTerms([])}}>Cancelar</BtnCancelar>
        <BtnBlue  data-id={idPost} onClick={showAlertFinalizar}>Aceptar</BtnBlue>
        </ContainerButton>

        </div>
    </Alert>
    <Alert
      estado={modalCompartir}
      >
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p>¿Estás seguro de que deseas <strong>compartir la publicación con<br></br>
          {nombre}</strong>
        </p>
        <div>
        <ContainerButton>
        <BtnCancelar onClick={()=> {setModalCompartir(false);setSearchTerms([])}}>Cancelar</BtnCancelar>
        <BtnBlue  data-id={idPost} onClick={compartirPost}>Aceptar</BtnBlue>
        </ContainerButton>

        </div>
    </Alert>
    <Alert
      estado={modalNoCompartir}
      >
      <ContainerModal>
          <ModalWrapper>
      <BtnClose onClick={() => setModalNoCompartir(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
        <img src={PATH + "img/icono-info.svg"} alt="info"/>
        <h3>Confirmación</h3>
        <p><strong>{nombre} </strong><br></br>
        No cumple con el porcentaje esperado para poder postular a esta publicación
         
        </p>
        <div>

        <CapSkills itemsData={showDetailsUserTags} />

        </div>
        
          </ModalWrapper>
          </ContainerModal>
    </Alert>
    <Alert
      estado={modalAdd}
      justify="flex-start"
      width="600px"
      >
      <>

            <SearchContainer>
            <BtnClose onClick={() => setModalAdd(false)}>
              <img src={PATH + "img/Cerrar-azul.svg"} alt="Close" />
            </BtnClose>
            <h5>
              Agregar postulantes:
            </h5>
            <InputSearchFilter $width="100%">
              <input type="text" placeholder="Buscar postulantes" value={searchTerm} onChange={handleChange} />
              <img src={PATH + "img/lupa-azul.svg"} alt="" />
            </InputSearchFilter>
            <ContainerPostulante>
                {(() => {
                  if(searchTerm.length > 2){
                    return userPerfiles.filter(obj => obj.nombreCompleto.toLowerCase().includes(searchTerm.toLowerCase())).map(perfiles => {
                      if(userMatches.filter(match => perfiles.id === match.fk_persona && match.idpost == `${idPost}`).length > 0 && userMatches.filter(match => perfiles.id === match.fk_persona && match.idpost == `${idPost}`)[0].average >= 50){
                        return <h4>{capitalizeWords(perfiles.nombreCompleto)}   <WhiteButton data-nombre={capitalizeWords(perfiles.nombreCompleto)} data-id={idPost} data-correo={perfiles.email} onClick={showCompartir}>Compartir</WhiteButton></h4>
                      }
                      else{
                        return <h4>{capitalizeWords(perfiles.nombreCompleto)} <WhiteButton data-id={perfiles.id} data-nombre={capitalizeWords(perfiles.nombreCompleto)} onClick={showNoCompartir}>Compartir</WhiteButton></h4>
                      }
                      });
                  }
                })()}  
            </ContainerPostulante>
          </SearchContainer>

            </>
    </Alert>
    </div>
  );
};

export default MatchesItems;


