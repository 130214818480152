import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetSelectors } from "../../store/slices/posts";
import { setPrevCap } from "../../store/slices/userAll";
import { ContainerGridCustom } from "../PostDetails/Styled";
import Form from "./Form/Form";
import PrevCap from "./PrevCap/PrevCap";

const OfferForm = () => {
  const dispatch = useDispatch();
  const userPais = useSelector(store => store.user.data.Pais);
  const token = useSelector(store => store.usuario.token);
  
  dispatch(setPrevCap(false))
  const [newCapData, setNewCapData] = useState({
    cargo: "",
    gerencia: "",
    estamento: "",
    jornada: "",
    ubicacion: "",
    fechaVencimiento: "",
    lider: 0,
    file: undefined,
    file3:"",
    mision: "",
    descripcion: "",
    funciones: "",
    skills: [],
    languages: [],
    interests: []
  });

  useEffect(() => {
    dispatch(fetchGetSelectors(userPais,token));
  }, []);

  return (
    <ContainerGridCustom columns="540px 540px">
      <Form newCapData={newCapData} setNewCapData={setNewCapData} upd={false}>
        OfferForm
      </Form>
      <PrevCap newCapData={newCapData} setNewCapData={setNewCapData} />
    </ContainerGridCustom>
  );
};

export default OfferForm;
