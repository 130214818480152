import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchGetSelectors } from "../../../store/slices/posts";
import DataTypeMaintainers from "./DataTypeMaintainers/DataTypeMaintainers";
import { ContainerMaintainers } from "./Styled";
import TypesMaintainers from "./TypesMaintainers/TypesMaintainers";

const Maintainers = () => {
  const dispatch = useDispatch();
  const dataUser = useSelector(store => store.user.data)
    const token = useSelector(store => store.usuario.token);
  
  const navigate = useNavigate()
  if(dataUser.administrador == false){
    navigate('/inicio')
  }
  useEffect(() => {

    dispatch(fetchGetSelectors('Peru',token));
  }, [dispatch]);

  return (
    <ContainerMaintainers>
      <TypesMaintainers />
      <DataTypeMaintainers />
    </ContainerMaintainers>
  );
};

export default Maintainers;
