import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../assets/variables";
import { fetchGetPosts } from "../../../store/slices/posts";
import Post from "../MyPosts/Post/Post";
import { Hr, Title } from "../../../Styled";
import { WrapperPosts } from "../MyPosts/Styled";
import { ImgEmpty } from "../Home/Feed/Caps/Styled";
import { fetchToken } from "../../customHooks/hooks";
import { setUserAllData } from "../../../store/slices/userAll";

const ListMatches = () => {
  const posts = useSelector(store => store.posts.totalPosts);
  const dataUser = useSelector(store => store.user.data)
  const token = useSelector(store => store.usuario.token);

  const dispatch = useDispatch();
  const FetchData = async () => {
    const dataUsers = await fetchToken({ endpoint: "Persona/GetAll", token:token });
    const dataSkills = await fetchToken({ endpoint: "Habilidad/GetAllTags" });
    const dataInteres = await fetchToken({ endpoint: "Interes/GetAllTags" });
    const dataLenguaje = await fetchToken({ endpoint: "Lenguaje/GetAllTags" });
    const skills = dataSkills;
    const interes = dataInteres;
    const lenguaje = dataLenguaje;


    let newData = dataUsers.map(item =>
      Object.assign({}, item, { habilidad: [], intereses: [], lenguajes: [] })
    );

    skills.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.habilidad.push(obj.nombre);
        }
      });
    });

    interes.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.intereses.push(obj.nombre);
        }
      });
    });
    lenguaje.forEach(obj => {
      newData.forEach(array1Obj => {
        if (obj.id === array1Obj.id) {
          array1Obj.lenguajes.push(obj.nombre);
        }
      });
    });
    dispatch(setUserAllData({post:newData,pais:'Peru'}));
  };

  useEffect(() => {
    FetchData()
  }, []);

  return (
    <WrapperPosts>
      <Title>Listado de coincidencias</Title>
      <Hr />
      {posts.length > 0 ? (
        posts.map(post => <Post key={post.data.id} post={post} />)
      ) : (
        
          <ImgEmpty>
            <img src={PATH + "img/sin_posts.svg"} />
          </ImgEmpty>
        
      )}
    </WrapperPosts>
  );
};

export default ListMatches;
