import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { PATH } from "../../assets/variables";
import { fetchGetPosts, fetchGetSelectors } from "../../store/slices/posts";
import { fetchInfoData, fetchInfoUser } from "../../store/slices/user";
import Menu from "./Menu/Menu";
import logo from '../../assets/img/logo-ematch.svg'
import home from '../../assets/img/home.svg'
import perfil from '../../assets/img/perfil.svg'

import {
  AdminContainer,
  AdminStyle,
  HeaderContainer,
  HeaderContainerMobile,
  HeaderStyle,
  MenuMobile,
} from "./Style";
import { fetchGeneralData } from "../../store/slices/generalData";

const Header = () => {
  const [admin, setAdmin] = useState(false);
  const [mobile, setMobile] = useState(false);
  // const [token, setToken] = useState(useSelector(store => store.usuario.token));

  const dispatch = useDispatch();
  const {data,token} = useSelector(store => store.usuario);
  const item = useSelector(store => store.user);

  useEffect(() => {
    //dispatch(fetchInfoUser(token));
    dispatch(fetchInfoData(token))
    if(item.loading == false){
      dispatch(fetchGeneralData(token));
      // dispatch(fetchGetPosts(data.Pais));
      dispatch(fetchGetPosts('Chile'));
      dispatch(fetchGetSelectors('Chile',token));
    }


  }, [dispatch,data,item.data.idColab]);
  

  return (
    <>
      <HeaderContainer>
        <HeaderStyle>
          <img src={logo} alt="logo empresa" />
          <Menu admin={admin} setAdmin={setAdmin} />
        </HeaderStyle>
      </HeaderContainer>
      <HeaderContainerMobile>
      <img src={PATH + "img/icon-menu-mobile.svg"} alt="logo menu" onClick={()=> setMobile(!mobile)} />
      <img src={logo} alt="logo empresa" style={{height:"32px"}} />

      </HeaderContainerMobile>
      <AnimatePresence>
      {mobile && (  
      <MenuMobile
        initial={{ y: -60, height: 0 }}
        animate={{ opacity: 1, y: 0, height: "115px" }}
        transition={{ type: "Tween" }}
        exit={{ y: -60, height: 0 }}
      >
      <div>
        <NavLink to="/inicio" onClick={()=> setMobile(!mobile)}>
          <img src={home} alt="home" />
          Inicio
        </NavLink>
        {/* <NavLink to="/buscador" onClick={()=> setMobile(!mobile)}>
              <img src={PATH + "searchWhite.svg"} alt="search" />
              Buscador de talentos
        </NavLink>  
        <NavLink to="/mis-publicaciones" onClick={()=> setMobile(!mobile)}>
          <img src={PATH + "request.svg"} alt="request" />
          Mis publicaciones
        </NavLink> */}
        <NavLink to="/perfil" onClick={()=> setMobile(!mobile)}>
          <img src={perfil} alt="profile" />
          Mi Perfil 
        </NavLink>
      </div>
      </MenuMobile>
      )}
      </AnimatePresence>


      <AnimatePresence>
        {admin && (
          <AdminContainer
            initial={{ y: -60, height: 0 }}
            animate={{ opacity: 1, y: 0, height: "50px" }}
            transition={{ type: "Tween" }}
            exit={{ y: -60, height: 0 }}
          >
            <AdminStyle>
              <div>
                <NavLink to="/nueva-oferta" onClick={()=> setAdmin(false)}>
                  <p>Formulario Nueva oferta</p>
                </NavLink>
              </div>
              <div>
                <NavLink to="/destacada" onClick={()=> setAdmin(false)}>Formulario Destacadas</NavLink>
              </div>
              <div>
                <NavLink to="/mantenedores" onClick={()=> setAdmin(false)}>Mantenedores</NavLink>
              </div>
              <div>
                <NavLink to="/coincidencias" onClick={()=> setAdmin(false)}>Listado de coincidencias</NavLink>
              </div>
            </AdminStyle>
          </AdminContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
